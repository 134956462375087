var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"prevent-select",attrs:{"prominent":"","app":"","short":true,"elevation":"1","id":"hdr","dark":true},on:{"click":_vm.c}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.xsOnly
				? `font-size: 13pt`
				: _vm.$vuetify.breakpoint.smAndDown
				? `font-size: 16pt`
				: '')},[_c('v-col',{staticStyle:{"text-align":"center","height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-row',{class:{
					'mt-1': !!_vm.$slots['header-line-2'],
				},style:(_vm.$slots['header-line-2'] || 'height: 112px;'),attrs:{"align":"center","justify":"center"}},[_c('h2',{staticStyle:{"font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.header)+" ")])]),(_vm.$slots['header-line-2'])?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"text-truncate"},[_vm._t("header-line-2")],2)]):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"text":"","absolute":"","bottom":"","left":""}},[_c('h2',[_vm._v(_vm._s(_vm.Time))])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && _vm.onDeckBoard.show)?_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","absolute":"","top":"","left":""}},[_c('h1',{class:_vm.shootingSquadColor,staticStyle:{"transition":"all 0.5s ease-in"}},[_vm._v(" On Deck: "+_vm._s(_vm.onDeckBoard.startingSquad)+"-"+_vm._s(_vm.onDeckBoard.lastSquad)+" ")])]):_vm._e(),_vm._t("scores-entered")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }