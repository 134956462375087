<template>
	<div style="height: 100%; width: 100%">
		<div v-if="!loaded" style="width: 100%">
			<v-row class="justify-space-around">
				<v-progress-circular
					class="mx-auto my-5"
					size="256"
					color="#F15A2B"
					width="16"
					indeterminate
				>
					<v-img
						:eager="true"
						src="icon.png"
						max-height="226"
						max-width="226"
					/>
				</v-progress-circular>
			</v-row>
			<v-row class="justify-space-around mt-5">
				<h2>Loading... Please wait</h2>
			</v-row>
		</div>
		<h1
			v-else-if="
				loaded && CurrentEvent && ScoresToShow && ScoresToShow.length < 1
			"
		>
			<center>
				There are no scores available yet.
				<br />
				<v-btn color="primary" to="/set/shoot">
					<v-icon class="mr-1"> mdi-home </v-icon>
					Go Home
				</v-btn>
			</center>
		</h1>
		<div style="width: 100%" v-else>
			<!-- REGULAR EVENT SCORES -->
			<ScrollScores
				v-if="
					!showWinner &&
					!showEventChangeModal &&
					ScoresToShow &&
					ScoresToShow.length &&
					!HOAorHAA
				"
				:key="load"
				:headerText="Header"
				:inScores.sync="ScoresToShow"
				:EvType="CurrentEvent.EvntTypeId"
				:targetsInEvent="CurrentEvent.NumberOfTargets"
				:displayClasses="displayClasses"
				:displayCategories="displayCategories"
				@settingsOpened="CloseConnectionError()"
				@LastGroupShown="UpdateEvent()"
			/>

			<!-- HOA/ShowHAA SCORES -->
			<ScrollScores
				v-if="!showEventChangeModal && HOAorHAA && !showWinner"
				:key="load"
				:headerText="Header"
				hoa
				:inScores="ScoresToShow"
				:targetsInEvent="100"
				:displayClasses="null"
				:displayCategories="null"
				EvType="Singles"
				@settingsOpened="CloseConnectionError()"
				@LastGroupShown="UpdateEvent()"
			/>

			<ScrollWinners
				v-if="showWinner && $store.state.shoot.shootoffs[evtToShowId]"
				:headerText="Header"
				:data="$store.state.shoot.shootoffs[evtToShowId]"
				:EvType="CurrentEvent.EvntTypeId"
				:key="`${evtToShowId}-${eventsShown}`"
				:eventsShown="eventsShown"
				@LastGroupShown="UpdateEvent()"
			/>
			<!-- @LastGroupShown="UpdateEvent()"
				@SecondToLast="UpdateScores()" -->
			<Announcements />

			<ScreenDialog v-model="showEventChangeModal">
				<div style="margin-top: calc(50vh - 130px)">
					<h1
						class="xl text-center"
						:style="$vuetify.theme.dark ? 'color:#ccc' : 'color:#555'"
					>
						Now Showing
					</h1>
					<v-container fluid>
						<v-row align="center" justify="center">
							<v-col align="center">
								<h1 class="xxl">
									<div v-if="!HOAorHAA">
										{{ Header }}
									</div>
									<div v-else-if="ShowHAA">High All Around</div>
									<div v-else>High Over All</div>
								</h1>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</ScreenDialog>
		</div>
		<v-snackbar
			v-model="connectionError"
			:timeout="0.5 * 60 * 1000"
			:multi-line="true"
		>
			<v-row>
				<v-col cols="auto shrink">
					<v-icon color="warning" x-large>
						mdi-access-point-network-off
					</v-icon>
				</v-col>
				<v-col>
					<center>
						<h2>
							There was an error fetching scores <br />
							Please check your internet connection
						</h2>
					</center>
				</v-col>
			</v-row>
		</v-snackbar>
		<NationalAnthem />
		<PictureODB v-if="hasPictureODB" />
		<TimerODB
			v-if="onDeckBoard.show && onDeckBoard.popupVariant !== 'disabled'"
			:force="force"
		/>
		<PunchesOverlay v-model="showPunchesOverlay" />
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ScrollScores from '../components/ScrollScores.vue';
import Announcements from '../components/Announcements.vue';
import ScreenDialog from '@/components/ScreenDialog.vue';
import NationalAnthem from '@/components/nationalAnthems.vue';
import PictureODB from '@/components/OnDeckBoards/PictureODB.vue';
import TimerODB from '@/components/OnDeckBoards/TimerODB.vue';
import ScrollWinners from '@/components/ScrollWinners.vue';
import PunchesOverlay from '@/components/PunchesOverlay.vue';
export default {
	name: 'home',
	components: {
		ScrollScores,
		Announcements,
		ScreenDialog,
		NationalAnthem,
		PictureODB,
		TimerODB,
		ScrollWinners,
		PunchesOverlay,
	},
	data() {
		return {
			loading: true,
			selectedEventIndex: 0,
			showEventChangeModal: false,
			showPunchesOverlay: false,
			connectionError: false,
			HOAorHAA: false,
			ShowHAA: false,
			hasHOAorHAA: false,
			hasHOA: false,
			hasHAA: false,
			lastUpdate: -1,
			load: 0,
			eventsShown: 0,
			blockOverrun: false,
			force: false,
			runThrough: 0,
		};
	},
	beforeCreate() {
		if (!this.$store.state.shoot.ClubID) {
			this.$router.push('/set/shoot');
			return false;
		}

		return true;
	},
	computed: {
		...mapState(['ShowHOAHAA']),
		...mapState('shoot', ['onDeckBoard']),
		...mapGetters('shoot', [
			'ScoresLoadCount',
			'Club',
			'loaded',
			'scores',
			'Categories',
			'Classes',
			'Events',
			'HOA',
			'HAA',
		]),
		Header() {
			if (this.HOAorHAA) {
				if (this.ShowHAA) {
					return 'High All Around';
				}
				return 'High Over All';
			} else if (!this.CurrentEvent) {
				return '';
			} else {
				return `${this.CurrentEvent.EvntLabel} - ${this.CurrentEvent.EvntName}`;
			}
		},
		EventsToShow() {
			switch (this.$store.state.EventScores) {
				default:
				case "Today's Event":
					return this.getTodaysEvents() || [this.getLatestEvent()];
				case 'All Events':
					return this.getAllEvents();
				case 'Latest Event':
					return [this.getLatestEvent()];
			}
		},
		evtToShowId() {
			return this.CurrentEvent.EvntId;
		},
		ScoresToShow() {
			if (this.HOAorHAA) {
				if (this.ShowHAA) {
					return this.HAA;
				}
				return this.HOA;
			}
			if (!this.latestEvent) return [];
			if (!this.CurrentEvent) return [];
			if (
				this.CurrentEvent.EvntTypeId === 'Doubles' &&
				this.CurrentEvent.NumberOfTargets === 200
			) {
				const len = this.scores[this.evtToShowId].length;
				for (let i = 0; i < len; i++) {
					const element = this.scores[this.evtToShowId][i];
					if (element.Score3) {
						continue;
					}
					element.Score3 = element.Score5;
					element.Score4 = element.Score6;
				}
			}
			this.scores;
			this.ScoresLoadCount;
			this.selectedEventIndex;
			this.CurrentEvent;

			return this.scores[this.evtToShowId];
		},
		CurrentEvent() {
			this.selectedEventIndex;
			this.Events;
			if (this.HOAorHAA) return null;
			return this.EventsToShow[this.selectedEventIndex];
		},
		todayEvts() {
			try {
				return this.getTodaysEvents();
			} catch (err) {
				console.error(err);
				return this.latestEvent;
			}
		},
		latestEvent() {
			return this.getLatestEvent();
		},
		allEvents() {
			return this.getAllEvents();
		},
		hasPictureODB() {
			let Club = this.$store.state.shoot.Club;
			if (Club)
				if (Club.ScoreboardSettings)
					if (Club.ScoreboardSettings.showODBCamera) return true;
			return false;
		},

		/**
		 * Gets the classes to be displayed
		 */
		displayClasses() {
			if (!this.CurrentEvent) return [];

			const classes = this.Classes[this.CurrentEvent.EvntTypeId].map(
				(x) => x.Label
			);

			if (classes.length <= 1) return [];

			return classes;
		},

		/**
		 * Gets the categories to be displayed
		 */
		displayCategories() {
			return this.$store.state.DisplayCategories && this.Categories
				? this.Categories.map((x) => x.CategoryId).filter((x) => x != 'None')
				: null;
		},

		showWinner() {
			if (!this.$store.state.ShowWinners) return false;
			this.EventsToShow;
			this.selectedEventIndex;
			if (this.HOAorHAA) {
				if (this.ShowHAA) {
					return this.$store.state.shoot.shootoffs.HAA;
				}
				return this.$store.state.shoot.shootoffs.HOA;
			}
			return !!this.$store.state.shoot.shootoffs[
				this.EventsToShow[this.selectedEventIndex].EvntId
			];
		},
	},
	async created() {
		if (!this.$store.state.shoot.ClubID) {
			this.$router.push('/set/shoot');
			return;
		}
	},
	mounted() {
		this.timerLoadScoresBackup();
		this.mobileCheck();
		this.checkHOAOrHAAScores();
		if (localStorage.darkmode != undefined) {
			// eslint-disable-next-line
			this.$vuetify.theme.dark = JSON.parse(localStorage.darkmode);
		} else {
			this.$vuetify.theme.dark = true;
		}

		var el = document.getElementById('maincontainer');
		el.classList.remove('v1');
		window.addEventListener('keydown', this.keyboardShortcuts);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.keyboardShortcuts);

		document
			.getElementById('viewport')
			.setAttribute(
				'content',
				'width=device-width, initial-scale=1, maximum-scale=5.0, minimum-scale=0.25, user-scalable=yes'
			);
	},
	watch: {
		ShowHOAHAA(v) {
			if (v) {
				this.UpdateScores();
			}
		},
		HOAorHAA(v) {
			if (!v) {
				this.ShowHAA = false;
			}
		},
		loaded() {
			if (!this.latestEvent) {
				if (this.onDeckBoard.show) {
					this.$vuetify.theme.dark = localStorage.darkmode || true;
					this.force = true;
					return;
				}
				this.$router.push('/set/shoot');
				this.$snackbar('No scores yet. Check back later.');
				return;
			}
		},
		HOA() {
			this.checkHOAOrHAAScores();
		},
		HAA() {
			this.checkHOAOrHAAScores();
		},
		selectedEventIndex() {
			this.eventsShown++;
		},
	},
	methods: {
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'p':
				case 'P':
					this.showPunchesOverlay = !this.showPunchesOverlay;
					break;
				case 'n':
				case 'N':
					this.showEventChangeModal = !this.showEventChangeModal;
					break;
			}
		},
		mobileCheck() {
			/* eslint-disable  */
			function isMobile() {
				let isMobile = false; //initiate as false
				// device detection
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
						navigator.userAgent
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						navigator.userAgent.substr(0, 4)
					)
				) {
					isMobile = true;
				}
				return isMobile || window.$forceMobile;
			}
			/* eslint-enable  */

			if (isMobile()) {
				//If landscape
				if (window.innerHeight > window.innerWidth) {
					document
						.getElementById('viewport')
						.setAttribute(
							'content',
							'width=device-width,initial-scale=0.6, maximum-scale=5.0, minimum-scale=0.25, user-scalable=yes'
						);
				} else {
					document
						.getElementById('viewport')
						.setAttribute(
							'content',
							'width=device-width,initial-scale=0.4, maximum-scale=5.0, minimum-scale=0.25, user-scalable=yes'
						);
				}
			}
		},
		timerLoadScoresBackup() {
			//Check if the last update occurred more recently than the timer
			// const lastUpdatePlusTen = this.lastUpdate + 9.98 * 60;
			// const nowEpoch = new Date().getTime() / 1000;
			// if (lastUpdatePlusTen < nowEpoch)

			this.UpdateScores(true);

			setTimeout(() => {
				this.timerLoadScoresBackup();
			}, 2 * 60 * 1000);
		},

		getAllEvents() {
			const es = [];
			for (let index = 0; index < this.Events.length; index++) {
				const evt = this.Events[index];
				if (evt.Status === 'Pending') continue;
				const scores = this.scores[evt.EvntId];

				if (scores && scores.filter((y) => y.Score200 > 0).length > 0)
					es.push(JSON.parse(JSON.stringify(evt)));
			}
			console.debug('EVENTS', es);
			return es;
		},

		triggerPunchesOverlay() {
			window.punchesOverlay = false;
			if (this.$store.state.yardagePunches?.length) {
				//If showing winners show less often this fn gets called twice in a row
				if (
					++this.runThrough < 9 &&
					this.showWinner &&
					this.$store.state.shoot.shootoffs[this.evtToShowId]
				) {
					return;
				} else {
					this.runThrough = 0;
					window.punchesOverlay = true;
				}
				this.showPunchesOverlay = true;
				setTimeout(() => {
					this.showPunchesOverlay = false;
				}, 20 * 1000);
			}
		},

		UpdateEvent() {
			if (this.blockOverrun) return;
			this.UpdateScores();
			let nowShowing = () => {
				if (
					this.$store.state.NowShowing &&
					(this.EventsToShow.length > 1 ||
						(this.ShowHOAHAA &&
							(this.HOA.filter((x) => x.Score200 > 0).length > 0 ||
								this.HAA.filter((x) => x.Score200 > 0).length > 0)))
				) {
					this.showEventChangeModal = true;
					setTimeout(() => {
						this.showEventChangeModal = false;
					}, 5 * 1000);
					setTimeout(this.triggerPunchesOverlay, 5 * 1000 - 250);
				} else {
					this.triggerPunchesOverlay();
				}
			};
			nowShowing();
			if (this.HOAorHAA) {
				if (this.ShowHAA) {
					this.HOAorHAA = false;
					this.ShowHAA = false;
				} else {
					if (!this.hasHAA) this.HOAorHAA = false;
					this.ShowHAA = true;
				}
				this.showWinner =
					!!this.$store.state.shoot.shootoffs[this.ShowHAA ? 'HOA' : 'HAA'];
				return;
			}

			nowShowing();
			if (this.selectedEventIndex + 1 >= this.EventsToShow.length) {
				if (this.ShowHOAHAA && this.hasHOAorHAA) {
					this.HOAorHAA = true;
					this.selectedEventIndex = 0;
				} else {
					this.selectedEventIndex = 0;
				}
				this.UpdateScores();
			} else {
				this.selectedEventIndex++;
			}

			//Rate limit 1 request per second
			this.blockOverrun = true;
			setTimeout(() => {
				this.blockOverrun = false;
			}, 10 * 1000);
			this.eventsShown++;
		},

		getTodaysEvents() {
			let result = null;
			let eventsWithScores = 0;
			const todaysEvents = [];
			const yesterdaysEvents = [];
			const today = new Date();
			const yesterday = new Date();
			yesterday.setDate(today.getDate() - 1);

			const todayString = today.toISOString().substring(0, 10);
			const yesterdayString = yesterday.toISOString().substring(0, 10);

			for (const key in this.Events) {
				const evt = this.Events[key];
				if (!evt.Events) evt.Events = [];
				const evtDate = new Date(evt.EvntDate);
				const eDate = evtDate.toISOString().substring(0, 10);
				if (todayString == eDate) {
					if (
						this.scores[evt.EvntId].filter((x) => x.Score200 > 0).length > 1
					) {
						eventsWithScores++;
						todaysEvents.push(evt);
					}
				} else if (yesterdayString == eDate) {
					yesterdaysEvents.push(evt);
				}
			}
			if (yesterdaysEvents.length < 1 && todaysEvents.length < 1) {
				//IF THE SHOOT IS OVER JUST RETURN THE LAST EVENT
				result = [this.getLatestEvent()];
			} else result = eventsWithScores == 0 ? yesterdaysEvents : todaysEvents;

			if (!result) return this.getAllEvents();
			else return result;
		},

		getLatestEvent() {
			if (!this.scores || Object.keys(this.scores).length < 1) return [];
			let lastScore = 0;
			for (const key in this.Events) {
				const evt = this.Events[key];
				if (evt.Status === 'Pending') {
					// if (!this.scores[evt.EvntId]) continue;
					// lastScore++;
					continue;
				}

				if (
					this.scores.length < this.Events.length &&
					this.scores.length - 1 == lastScore
				) {
					break;
				}

				if (
					this.scores[evt.EvntId] &&
					this.scores[evt.EvntId].filter((x) => x.Score200 > 0).length > 0
				) {
					lastScore++;
				}
			}

			if (lastScore - 1 < 0) return null;
			return this.Events[lastScore - 1];
		},

		async UpdateScores() {
			this.$store.dispatch('shoot/refreshScores');
		},

		async CloseConnectionError() {
			this.connectionError = false;
		},

		checkHOAOrHAAScores() {
			if (this.HOA && this.HOA.length) {
				this.hasHOA = true;
				this.hasHOAorHAA = true;
			}

			if (this.HAA && this.HAA.length) {
				this.hasHAA = true;
				this.hasHOAorHAA = true;
			}
		},
	},
};
</script>

<style>
.v1 {
	background: #fff !important;
}
.xl {
	font-size: 36px;
}
.xxl {
	font-size: 48px;
	text-transform: uppercase;
}
</style>
